@import '../variables/colors.scss';


.grey-bg {
  background-color: $light-grey;
}

#chart {
  h2 {
    font-weight: 300;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 18px;
    color: #086375;
    position: relative;
    width: fit-content;

    &::after {
      content: "";
      width: 35px;
      height: 1px;
      background: $mint;
      display: flex;
      position: absolute;
      right: -39px;
      bottom: 4px;
    }
  }
}

#currentPost {
  width: 100%;
  margin: 0;
  cursor: pointer;

  .currentPost-content {
    position: relative;
    height: 100%;
  }

  .featured {
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    position: relative;
    padding: 15px;
    text-align: right;

    .badge-category {
      position: absolute;
      top: 0;
      margin: 0 !important;
    }

    h4 {
      text-align: right !important;
    }
  }
}


.map-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.map {
  height: 210px;
}

section {
  position: relative;
  box-sizing: border-box;

  .main {
    width: 100%;
  }

  h2 {
    color: #086375;
    text-align: left;
  }

  ul {
    list-style-type: square;
    padding: 20px;

    &.cat-list {
      padding-left: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

ul.colored-list {
  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .list-bullet {
    color: $mint; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    font-size: 1.6em;
  }
}

.recent-posts-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &.bg-gradient {
    background: rgb(28, 212, 176);
    background: linear-gradient(145deg, rgba(28, 212, 176, 1) 0%, rgba(7, 90, 125, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1cd4b0", endColorstr="#075a7d", GradientType=1);
  }

  .grey-overlay {
    background-color: #00000025;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .recent-posts {
    img {
      max-width: 18%;
      align-self: flex-start;
    }
  }
}

// categories et agenda

@media screen and (max-width: 768px) {
  .category-section {
    margin-bottom: 21px!important;
  }
}

.category-section {
  margin-bottom: 100px;

  //.big-section {
  //  flex: 0 0 72%;
  //  max-width: 72%;
  //}
  //.small-section {
  //  flex: 0 0 28%;
  //  max-width: 28%;
  //}

  .featured {
    background-color: #dfebed;
    padding: 25px 20px;

    .featured-title {
      font-size: 18px;
    }
  }
}

.a-la-une {
  margin-top: -30px;
}

//carousel vertical
#carousel-vertical {
  height: 310px;
  overflow: hidden;

  #carousel-vertical-slides {
    transition: 0.7s all ease;
    margin-top: 0;

    .carousel-vertical-slide {
      height: 90px;
      cursor: pointer;

      .carousel-vertical-slide-title {
        line-height: 1.3em;
        height: 2em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        padding-bottom: 37px;
      }
    }
  }

  hr {
    border-top-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

//map
@media screen and (max-width: 768px) {
  .index-map-container {
    padding: 0 !important;
  }

  h2, h2 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.map-see-more-btn {
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}

.carousel-vertical-thumbnail {
  background-position: center;
  background-size: cover;
  height: 90%;
  position: relative;
  aspect-ratio: 16/9;
  margin-right: 10px;
  max-width: initial;
  padding-left: 5px;

  .badge-category {
    margin-top: 5px;
    padding: 5px 10px;

    p {
      font-size: 8px;
    }
  }
}

.carousel-vertical-slide {
  padding-left: 15px;

  .read-more {
    font-style: italic;
    font-size: 10px;
    margin-top: 5px;
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .carousel-vertical-thumbnail {
    display: none !important;
  }
}

.carousel-vertical-controls {
  position: absolute;
  right: 6px;
  bottom: 6px;

  .control {
    background: rgba(0, 0, 0, 0.5);
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: white;
    }
  }
}

// responsive
@media screen and (max-width: 768px) {
  //map
  .map {
    align-items: center;

    p {
      display: none;
    }

  }
}

.tarif {
  padding: 10px;
}

.tarif-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #096375;
  text-align: center;
}

.tarif-subtitle {
  color: #b1b1b1;
}

.tarif-content {
  padding-top: 30px;
}
